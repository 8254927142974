import React, {useCallback, useEffect, useState} from "react";
import {projectTheme} from "./prjectTheme";
import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ThemeProvider,
    Typography
} from "@mui/material";
import ProjectNav from "./project-page-components/ProjectNav";
import thumbnail from "./poshmark-asset/poshmark-thumbnail.png";
import poshmarkPage from "./poshmark-asset/poshmark-page.png";
import poshmarkPage2 from "./poshmark-asset/poshmark-page-2.png";

interface ProjectPagePoshmarkProps {
    setPage: React.Dispatch<React.SetStateAction<string>>
}


export default function ProjectPagePoshmark(props: ProjectPagePoshmarkProps) {
    const [y, setY] = useState(window.scrollY);
    const [showNavBar, setShowNavBar] = useState(true);
    const handleNavigation = useCallback(
        (e: { currentTarget: any; }) => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setShowNavBar(true);
            } else if (y < window.scrollY) {
                if (y > 800) {
                    setShowNavBar(false);
                }

            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);


    return (
        <ThemeProvider theme={projectTheme}>
            <Box
                onMouseEnter={() => {
                    setShowNavBar(true)
                }}
                sx={{
                    width: "100%",
                    height: "4rem",
                    position: "fixed",
                    top: 0
                }}/>
            <ProjectNav isScrollingUp={showNavBar} setPage={props.setPage}/>
            <Container sx={{
                display: "flex",
                flexDirection: "column",
                width: {
                    xs: '90%',
                    sm: '80%',
                    md: '80%',
                    lg: '70%'
                }
            }}>
                <Typography variant={'h1'} sx={{marginTop: "5rem"}}>
                    Responsive Redesign: Poshmark
                </Typography>
                <Typography variant={'subtitle1'}
                            sx={{marginBottom: "2rem"}}>
                    Redesigning Poshmark to make it more usable | Making a
                    responsive page
                </Typography>

                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}>
                            Context and Problem Statement
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography variant={'body1'}>
                            As someone who frequently browses online secondhand
                            shops, I noticed that the shopping experience on
                            Poshmark has often been sub-optimal. However, it has
                            one of the largest databases of secondhand apparel
                            products, so I decided to redesign the landing page
                            of Poshmark to make it easier for users to shop.

                            As I was redesigning the webpage, I tried to keep in
                            mind the goals that the original webpage was trying
                            to achieve so that I can make the webpage more
                            usable without sacrificing the original goals of the
                            page.
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Box component={'img'} src={thumbnail}/>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant={'h2'}>
                            Identifying Usability Problems
                        </Typography>
                    </Grid>

                    <Grid item sm={12} md={6}>
                        <Box component={'img'} src={poshmarkPage}/>
                        <Box component={'img'} src={poshmarkPage2}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography variant={'body1'}>
                            The landing page of Poshmark is not very
                            <b>&nbsp;learnable</b>.
                            It
                            is difficult to navigate the site as a first time
                            user
                            because they expect to see a navigation bar with
                            different product categories and a search bar to
                            look up
                            specific keywords, but those are not present. In a
                            simplified user test where the participants were
                            asked
                            to find a pair of jeans, half of them were not able
                            to
                            find any pair within 30 seconds.
                        </Typography>
                        <br/>


                        <Typography variant={'body1'}>
                            In terms of<b>&nbsp;memorability</b>, the landing
                            page of
                            Poshmark
                            makes it difficult for casual users to recall what
                            to do
                            because the webpage contains a lot of random
                            information
                            that is not directly relevant to shopping.
                        </Typography>
                        <br/>

                        <Typography variant={'body1'}>
                            This webpage is not<b>&nbsp;efficient</b> for
                            frequent users
                            either.
                            To navigate to the product search page, they have to
                            first pick a random brand that they might not even
                            be
                            interested in.
                        </Typography>

                        <br/>

                        <Typography variant={'body1'}>
                            Other problems include:
                        </Typography>

                        <List>
                            <ListItem>
                                <Typography variant={'body1'}>
                                    1. The most significant part of the real
                                    estate
                                    is used to prompt users to login/register,
                                    even though it is not the main purpose of
                                    people’s visit.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={'body1'}>
                                    2. Having both “Top selling brands this
                                    week”
                                    and “(brands) Trending Now” is a little
                                    redundant.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={'body1'}>
                                    3. Having both “Top selling brands this
                                    week”
                                    and “(brands) Trending Now” is a little
                                    redundant.
                                </Typography>
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant={'h2'}>
                            Redesign
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Box>
                            <iframe
                                onClick={() => {
                                    window.open('https://olddragon000.github.io/poshmark-redesign/')
                                }}
                                width={'100%'} height={'1000rem'}
                                src={'https://olddragon000.github.io/poshmark-redesign/'}/>

                        </Box>

                    </Grid>

                </Grid>

            </Container>
        </ThemeProvider>
    )
}