import {createTheme} from "@mui/material";
import "./fonts.css"

export const theme = createTheme({
    palette:{
        primary:{
            main:"#EF7B45"
        },
        secondary:{
            main: "#4C956C"
        }
    },
    typography:{
        //Section heading
        h3:{
            // fontFamily: "Space Mono",
            fontSize: "2rem",
            fontWeight: "600",
            marginBottom: "3rem"
        },

        body1: {
            fontFamily: "Space Mono"
        },
    },
})