import React, {useEffect, useState} from "react";
import Home from "./Home";
import ProjectPageWinno from "./project-page/ProjectPageWinno";
import {Box} from "@mui/material";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import ProjectPagePoshmark from "./project-page/ProjectPagePoshmark";
import ProjectPageSift from "./project-page/ProjectPageSift";

export default function App() {

    const [page, setPage] = useState<string>("home");
    let component;
    switch (page) {
        case "home":
            component = <Home setPage={setPage}/>
            break
        case "0":
            component = <ProjectPageWinno setPage={setPage}/>
            break
        case "1":
            component = <ProjectPagePoshmark setPage={setPage}/>
            break
        case "2":
            component = <ProjectPageSift setPage={setPage}/>
            break;
        default:
            component = <Home setPage={setPage}/>
    }

    useEffect(()=>{
        if(page === '3'){
            window.open('https://its-jz.github.io/lenti-webiste/');
        }
    },[page]);


    return (
        <Box>
            {component}
            <Footer/>

        </Box>
    )
}