import React, {useCallback, useEffect, useState} from 'react';
import './Home.css';
import {
    Box,
    Container,
    Grid,
    SpeedDial,
    SpeedDialAction,
    ThemeProvider
} from "@mui/material";
import {theme} from "./theme";
import NavBar from "./components/NavBar";
import Intro from "./components/Intro";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import AboutMe from "./components/AboutMe";
import Projects from "./components/projectsDisplay/ProjectsDisplay";
import Music from "./components/Music";
import ProjectsFlatDisplay from "./projects/ProjectsFlatDisplay";
import Footer from "./components/Footer";

interface HomeProps{
    setPage: React.Dispatch<React.SetStateAction<string>>
}
function Home(props: HomeProps) {
    const [y, setY] = useState(window.scrollY);
    const [showNavBar, setShowNavBar] = useState(true);
    const speedDialActions = [
        {icon: <LinkedInIcon/>, name: 'LinkedIn'},
        {icon: <EmailIcon/>, name: 'Email'},
    ];


    const handleNavigation = useCallback(
        (e: { currentTarget: any; }) => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setShowNavBar(true);
            } else if (y < window.scrollY) {
                if (y > 800) {
                    setShowNavBar(false);
                }

            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <ThemeProvider theme={theme}>
            <Box id={'poshmark'}
                sx={{
                }}>
                <Box
                    onMouseEnter={() => {
                        setShowNavBar(true)
                    }}
                    sx={{
                        width: "100%",
                        height: "4rem",
                        position: "fixed",
                        top: 0
                    }}/>
                <NavBar isScrollingUp={showNavBar}/>
                <Intro/>

                <Container
                    sx={{
                        padding: {
                            sm: "0 3rem"
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={0} sm={1}>
                        </Grid>
                        <Grid item xs={12} sm={11}>
                            <Box id={"about"} sx={{paddingTop: "4rem"}}>
                                <AboutMe/>
                            </Box>

                        </Grid>

                        <Grid item xs={12} >
                            <Box id={"projects"} sx={{paddingTop: "4rem"}}>
                                {/*<Projects/>*/}
                                <ProjectsFlatDisplay setPage={props.setPage}/>
                            </Box>
                        </Grid>

                        <Grid item xs={0} sm={1}>
                        </Grid>
                        <Grid item xs={12} sm={11}>
                            <Box id={"music"} sx={{paddingTop: "4rem"}}>
                                <Music/>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>

            </Box>
        </ThemeProvider>

    );
}

export default Home;
