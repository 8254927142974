import {Box} from "@mui/material";
import {SocialIcon} from "react-social-icons";

export default function SocialMediaIcons() {
    const circledIconBtn = (link: string) => {
        return (
            <Box sx={{
                borderRadius: '50%',
                "&:hover": {
                    filter: "contrast(175%)",
                    boxShadow: '0 0 2px 3px rgb(76, 149, 108)',
                    transition: "box-shadow 0.2s ease-in-out"
                },

            }}>
                <SocialIcon url={link}/>
            </Box>
        )
    }
    return (
        <Box sx={{
            // backgroundColor: "blue",
            display: "flex",
            justifyContent: "center",
            gap: "1rem"
        }}>
            {circledIconBtn("https://www.linkedin.com/in/jialiang-zhou-4a3a9b1b3/")}
            {circledIconBtn("https://github.com/its-jz")}
            {circledIconBtn("mailto:jialiang_zhou@brown.edu")}
        </Box>
    )
}