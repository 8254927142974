import React, {useCallback, useEffect, useState} from "react";
import {projectTheme} from "./prjectTheme";
import {Box, Container, Grid, ThemeProvider, Typography} from "@mui/material";
import ProjectNav from "./project-page-components/ProjectNav";
import thumbnail from "./sift-asset/sift-thumbnail.png";

interface ProjectPageSiftProps {
    setPage: React.Dispatch<React.SetStateAction<string>>
}

export default function ProjectPageSift(props: ProjectPageSiftProps) {
    const [y, setY] = useState(window.scrollY);
    const [showNavBar, setShowNavBar] = useState(true);
    const handleNavigation = useCallback(
        (e: { currentTarget: any; }) => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setShowNavBar(true);
            } else if (y < window.scrollY) {
                if (y > 800) {
                    setShowNavBar(false);
                }

            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <ThemeProvider theme={projectTheme}>
            <Box
                onMouseEnter={() => {
                    setShowNavBar(true)
                }}
                sx={{
                    width: "100%",
                    height: "4rem",
                    position: "fixed",
                    top: 0
                }}/>
            <ProjectNav isScrollingUp={showNavBar} setPage={props.setPage}/>
            <Container sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                <Typography variant={'h1'} sx={{marginTop: "5rem"}}>
                    Chrome Extension Development: Sift
                </Typography>
                <Typography variant={'subtitle1'}
                            sx={{marginBottom: "2rem"}}>
                    Building a Product to Solve a Real-world Problem
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}>
                            Context and Problem Statement
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography variant={'body1'}>
                            {"I put my product design and frontend development skills to test " +
                            "with my startup Sift. \n\nEven though 70% of Americans are willing to purchase " +
                            "secondhand clothing items, only 30% do. Shopping secondhand, especially online, is not " +
                            "as popular as it should be because people already have a habit of shopping on fast " +
                            "fashion sites and secondhand sites are harder to navigate. \n\nSift is a Chrome extension" +
                            " that allows people to find secondhand alternatives to the fast fashion " +
                            "products that they are interested in within a click.\n\n" +
                            "Apart from leading the product development and engineering process and ensuring that the team is " +
                            "communicating well, I was also in charge " +
                            "of the entire frontend development process. content scripts, I built an extension " +
                            "that can extract product information on a page, " +
                            "send product information to the backend, receive recommendations and display products " +
                            "on the webpage."}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Box component={'img'} src={thumbnail}/>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant={'h2'}>
                            Beta Coming soon
                        </Typography>
                        <Typography variant={'subtitle1'} sx={{fontSize:'1.5rem'}}>
                            Meanwhile, you can check out our <a href={'https://www.letsift.com/'}>website</a> and join the waitlist.
                        </Typography>
                    </Grid>

                </Grid>
            </Container>

        </ThemeProvider>

    )
}