import {Box, Typography} from "@mui/material";
import React from "react";

export default function Music(){
    return(
        <Box
            sx={{
                width: "100%"
            }}>
            <Typography variant={"h3"}>
                <span style={{color: "#4C956C"}}>03.&nbsp;</span>Music
            </Typography>
            <Box>
                <div style={{padding:"56.25% 0 0 0",position:"relative"}}>
                    <iframe
                        src="https://player.vimeo.com/video/783364447?h=cfda19bf05&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
                        title="XBD final"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </Box>
        </Box>
    )
}