import {
    Box, Button, Card,
    Container,
    Grid,
    List,
    ListItem,
    Paper,
    ThemeProvider,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import ProjectNav from "./project-page-components/ProjectNav";
import {projectTheme} from "./prjectTheme";
import thumbnail from "./winno-asset/winno-thumbnail.jpeg"
import wf2 from "./winno-asset/Wireframe2.png";
import wf1 from "./winno-asset/Wireframe1.png";
import wf3 from "./winno-asset/Wireframe3.png";

interface ProjectPageWinnoProps {
    setPage: React.Dispatch<React.SetStateAction<string>>
}

export default function ProjectPageWinno(props: ProjectPageWinnoProps) {
    const [y, setY] = useState(window.scrollY);
    const [showNavBar, setShowNavBar] = useState(true);

    const styledPaper = (title: string, body: string) => <Box>
        <Typography variant={'h3'}>
            {title}
        </Typography>
        <Typography variant={'body1'}>
            {body}
        </Typography>
    </Box>

    const handleNavigation = useCallback(
        (e: { currentTarget: any; }) => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setShowNavBar(true);
            } else if (y < window.scrollY) {
                if (y > 800) {
                    setShowNavBar(false);
                }

            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);


    return (
        <ThemeProvider theme={projectTheme}>
            <Box id={'winno'}>

                    <Box
                        onMouseEnter={() => {
                            setShowNavBar(true)
                        }}
                        sx={{
                            width: "100%",
                            height: "4rem",
                            position: "fixed",
                            top: 0
                        }}/>
                <ProjectNav isScrollingUp={showNavBar} setPage={props.setPage}/>


                <Container sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography variant={'h1'} sx={{marginTop: "5rem"}}>
                        Iterative Design: Winno
                    </Typography>
                    <Typography variant={'subtitle1'}
                                sx={{marginBottom: "2rem"}}>
                        Product Design Exercise: From Prototyping to User
                        Testing
                    </Typography>

                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                Context and Problem Statement
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Typography variant={'body1'}>
                                For this project, our team decided to redesign
                                Winno, a mobile app designed to allow users to
                                connect with experts to answer pressing
                                questions with insight into different
                                industries, without looking at the original
                                design of the product. Through this application,
                                we address 2 key needs. First, users who want to
                                learn want to connect with experts in different
                                fields to answer pressing questions. Second,
                                experts and creators want to own their audience
                                and get paid directly by their top fans. Winno
                                does just that. It allows users to discover
                                experts and to schedule text sessions, and
                                experts get paid for helping out and doing what
                                they love.
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Box component={'img'} src={thumbnail}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                Initial Wireframes
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={10} sx={{
                                paddingLeft: "5rem",
                                paddingRight: "5rem",
                            }}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box sx={{borderRadius: '2rem'}}
                                         component={'img'} src={wf2}/>
                                    <Typography variant={'h3'}
                                                sx={{marginTop: "3rem"}}>
                                        Home Page
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains a search bar for
                                                searching using direct keyword
                                                input
                                            </Typography>

                                        </ListItem>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains My Upcoming Session
                                                Cards to make users' booked
                                                sessions easy to find
                                            </Typography>
                                        </ListItem>

                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains recommended section
                                                that expose users to influencers
                                                they might be interested in
                                            </Typography>
                                        </ListItem>

                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains category options for
                                                user browsing and exploration
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box sx={{borderRadius: '2rem'}}
                                         component={'img'} src={wf1}/>
                                    <Typography variant={'h3'}
                                                sx={{marginTop: "1rem"}}>
                                        Category Page
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains different
                                                sub-categories to cater to the
                                                users' specific interests
                                            </Typography>

                                        </ListItem>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains influencers' profile
                                                picture, names, and specialties
                                                for user to identify and compare
                                                key information
                                            </Typography>
                                        </ListItem>

                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains a "See More" buttons
                                                for user to dive deep into their
                                                topic of interest
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Box sx={{borderRadius: '2rem'}}
                                         component={'img'} src={wf3}/>
                                    <Typography variant={'h3'}
                                                sx={{marginTop: "1rem"}}>
                                        Sub-Category Page
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                Contains more
                                                experts/influencers under the
                                                same subcategory for users to
                                                explore
                                            </Typography>

                                        </ListItem>
                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                The experts/influencers are
                                                displayed in cards, which
                                                showcases their key information
                                            </Typography>
                                        </ListItem>

                                        <ListItem>
                                            <Typography variant={'body1'}>
                                                The experts/influencers are
                                                displayed in cards, which
                                                showcases their key information
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                Version 1: Pre-Critique
                            </Typography>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3rem"
                            }}>
                                <iframe
                                    style={{border: "1px solid rgba(0, 0, 0, 0.1)"}}
                                    width="800" height="450"
                                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FgJqvNYfj2K9s0I5hGPJJew%2FPre-crit%3Fnode-id%3D10%253A84%26scaling%3Dscale-down%26page-id%3D10%253A54%26starting-point-node-id%3D10%253A84%26show-proto-sidebar%3D1"
                                    allowFullScreen/>
                            </Box>
                            <Typography variant={'h3'} sx={{marginTop: '2rem'}}>
                                What we did
                            </Typography>
                            <Typography variant={'body1'} sx={{marginTop: '1rem'}}>
                                Implemented a rudimentary design/user flow that
                                encompasses the browsing experts and messing
                                experts feature of the app
                            </Typography>

                            <Typography variant={'h3'} sx={{marginTop: '2rem'}}>
                                Peers & Industry Expert Critique Feedback
                            </Typography>
                            <List>
                                <ListItem>
                                    <Typography variant={'body1'}>
                                        The size of buttons on the main and
                                        subcategory pages are small, which makes
                                        them harder to notice
                                    </Typography>

                                </ListItem>
                                <ListItem>
                                    <Typography variant={'body1'}>
                                        The profile pictures on the main and
                                        subcategory pages are small, which makes
                                        the users struggle to see them clearly
                                    </Typography>
                                </ListItem>

                                <ListItem>
                                    <Typography variant={'body1'}>
                                        The back button on the message page plus
                                        the navigation bar creates confusion in
                                        navigation
                                    </Typography>
                                </ListItem>

                                <ListItem>
                                    <Typography variant={'body1'}>
                                        The animation of the navigation bar
                                        creates a confusing mental model
                                    </Typography>
                                </ListItem>
                            </List>

                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                Version 2: Post-Critique
                            </Typography>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3rem"
                            }}>
                                <iframe
                                    style={{border: "1px solid rgba(0, 0, 0, 0.1);"}}
                                    width="800" height="450"
                                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fr0gedeQDX7fsYlYv7v7knz%2FPre-User-Test%3Fnode-id%3D233%253A1179%26scaling%3Dscale-down%26page-id%3D10%253A54%26starting-point-node-id%3D233%253A1179"
                                    allowFullScreen/>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                User Testing
                            </Typography>
                        </Grid>
                        <Grid container xs={12} spacing={2}
                              sx={{marginTop: '2rem',padding:'0 3rem'}}>
                            <Grid item sm={6} md={6}>
                                {styledPaper('Task', 'Please think' +
                                    ' aloud to let up know what you notice, what ' +
                                    'you think about the app, and what you intend ' +
                                    'with youractions. Also, I just want to let you ' +
                                    'know that you can be completely honest ' +
                                    'about your experience with the applications, ' +
                                    'we appreciate your input.')}
                            </Grid>
                            <Grid item sm={6} md={6}>
                                {styledPaper('Context', 'This is a mobile prototype so everything inside of the Figma file will not be clickable. Please use the mic to explain through your thinking process as this will help us gather your valuable input about the app. Thank you!')}
                            </Grid>
                            <Grid item sm={6} md={6}>
                                {styledPaper('Scenario', 'You hurt your hips playing basketball and the pain has been bugging you for weeks. You want to find an expert that can recommend stretching exercises for recovery and pain relief.')}
                            </Grid>
                            <Grid item sm={6} md={6}>
                                {styledPaper('Main Objective','Browse the app and schedule an appointment with an expert that you think can best help with your injury.')}
                            </Grid>
                            <Box
                                sx={{
                                    marginTop:'2rem',
                                    width:'100%',
                                    display:'flex',
                                    justifyContent:'center',
                                    gap:'1rem'

                                }}>
                                {['https://app.usertesting.com/v/66039fe5-54c3-49ba-b62e-9f5f1a96d79e?encrypted_video_handle=8d48ce4a-ea97-4464-a9f7-5ba2c20cc39a#!/notes',
                                    'https://app.usertesting.com/v/73a5bd26-077b-41bd-9529-7296da6114a6?encrypted_video_handle=99dd7347-6488-4eda-ab5c-a8eb256514c2#!/notes',
                                    'https://app.usertesting.com/v/fc9a2fc2-b584-41bc-a28e-c0cd2dfa0618?encrypted_video_handle=5ea2bb34-34be-4ea5-b371-f64edb1a34ce#!/notes'].map((link,index) =>
                                    <Button variant="contained">
                                        <Typography variant={'body1'}>
                                            {'Test '+ (index+1).toString()}
                                        </Typography>
                                    </Button>
                                )}
                            </Box>
                            <Typography variant={'h2'} sx={{marginTop: '2rem'}}>
                                Analysis
                            </Typography>

                            <List>
                                <ListItem sx={{display:'flex',flexDirection:'column',marginTop:'2rem'}}>
                                    <Typography variant={'h3'}>
                                        Task 1: Without leaving the page, tell us what you notice and what you can do on this page. [Verbal response]
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        For the first task we wanted to get the users initial impressions of the home page to see if there were any things that stood out to them in a positive or negative way. This might give us insight on how different users perceived the functionality of the app. User one and user two gave positive feedback on organization and design of the home page. They said that the layout was simple and that it displayed the product in a direct and straightforward way. User one made a comment on the color scheme of the app which was similar to “facebook colors” to him. This made him feel like the app was affiliated with the company or had messaging functionality. User three also liked the layout of the home page but did mention that the information is a little overwhelming. We expected users to have a variety of impressions, but it was definitely good to see that all three recognized the main functionality of the app which was to connect users with experts in different fields. The comments made on the simplicity of our layout proved that our design is user-friendly.
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{display:'flex',flexDirection:'column',marginTop:'2rem'}}>
                                    <Typography variant={'h3'}>
                                        Task 2: Imagine you hurt your hips and the pain has been bugging you for weeks. Browse the app and schedule an appointment with a stretching expert to help with your injury. Think out loud as you perform this task and tell us what functionality is missing if any. [5-point rating scale: Very difficult to Very easy]
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        We wanted to simulate a use case of the app by giving users a scenario they need the app for. This task also allows users to interact with the key functionalities of the app and to show us if there are any issues with our implementation. All three users were able to complete the task successfully and gave the task a rating of 5 which represented very easy. In the video it seemed that the interactive elements were intuitively placed. User one was not able to find the stretching expert directly but after clicking around the categories she was able to find it. Even though she struggled a little bit, in the audio recording she described the process as “simple” and “straightforward”. User two was able to find the stretching expert directly and said that everything was “where they were supposed to be”. User three was expecting the stretching expert to be located in a different subcategory because she didn’t see the labels of the specialty fields we had put under the name of the experts. She was still able to complete the task fairly quickly and the fact that she struggled in the beginning didn’t bother her too much. We were expecting some users to struggle in this process because of the missing functionality that wasn’t relevant to the task but in general users did well. They were also able to complete the tasks much faster than we expected and all described the process as very easy.
                                    </Typography>
                                </ListItem>
                                <ListItem sx={{display:'flex',flexDirection:'column',marginTop:'2rem'}}>
                                    <Typography variant={'h3'}>
                                        Task 3:Outside of playing sports you are also an entrepreneur trying to start a yoga studio. You scheduled an appointment with Amy Nguyen, a yoga youtuber, for advice on this venture. The session is starting so please join the chat. Talk us through your thought process. [5-point rating scale: Very difficult to Very easy]
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        Since users already took a close look at the home page in the first task, this task proved to be fairly easy for all the users that tested our application. The home page already provided a one click access to Amy’s messages so users did not struggle with understanding that a session was currently being held. Our expectations matched the users’ inputs with all of them succeeding with the task; however, one small behavior that did not match our expectation was how two of the three users did not check the messages tab to text Amy. This was not a problem as there were two routes to take to succeed in this task: checking the messages tab or going to Amy’s chat directly through the home page. Since both routes resulted in the task being completed, the fact that most of our users went with the second route surprised us a little, but it did not affect their success.
                                    </Typography>
                                </ListItem>
                            </List>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                Final Product Demo
                            </Typography>
                            <Box sx={{
                                marginTop: '2rem',
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                            }}>
                                <iframe width="560" height="315"
                                        src="https://www.youtube.com/embed/-LNDtof78Ys"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen/>
                            </Box>
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </ThemeProvider>

    )
}