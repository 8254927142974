import {Box, Fade, Typography} from "@mui/material";
import React from "react";

interface ProjectNavProps {
    isScrollingUp: boolean
    setPage: React.Dispatch<React.SetStateAction<string>>
}

export default function ProjectNav(props: ProjectNavProps) {
    const navBarOptionStyles = {
        fontSize: {
            xs: "0.8rem",
            sm: "1.1rem",
            md: "1.5rem"
        },
        fontWeight: 700,
        letterSpacing: "0.001rem",
        fontFamily: "Space Mono",
        marginRight: "0.5rem",
        cursor: "pointer",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",

    }
    return (
        <Fade in={props.isScrollingUp}>
            <Box
                sx={{
                    backdropFilter: "blur(3px)",
                    position: "sticky",
                    top: 0,
                    height: "4rem",

                }}>


                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: 2,
                        marginLeft: {
                            xs: 2,
                            md: 5
                        },
                        marginRight: {
                            xs: 2,
                            md: 5
                        },
                        position: "relative"
                    }}>
                    <Box
                        onClick={()=> {
                            window.scrollTo(0, 0)
                            props.setPage("home")
                        }}
                        sx={{
                            display: "flex",
                        }}>
                        <Typography variant="h5" component="div"
                                    sx={{
                                        fontFamily: "Ultra",
                                        fontSize: {
                                            xs: "2rem",
                                            sm: "3rem",
                                            md: "3rem"
                                        },
                                        color: "#EF7B45",
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        flexGrow: 20,
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                            JZ
                        </Typography>


                    </Box>
                </Box>
            </Box>
        </Fade>
    )
}