import {Box, Grid, Typography} from "@mui/material";
import profilePic from "../asset/images/profile.png";
import SocialMediaIcons from "./SocialMediaIcons";

export default function AboutMe() {


    const bio: string = "My name is Jialiang Zhou, and I go by my initials JZ." +
        " I'm a third year Chinese international student " +
        "studying Computer Science at Brown University. \n\nI love finding and " +
        "addressing problems in products, and I am most passionate about creating " +
        "scalable and impactful products to make people's lives more convenient. " +
        "\n\nOutside of the technical realm, I also spend a lot of time on music. " +
        "I write, produce, record music, ranging from bedroom pop to alternative rap."


    return (
        <Box
            sx={{
                width: "100%"
            }}>

            <Box
                sx={{}}>
                <Typography variant={"h3"}>
                    <span style={{color: "#4C956C"}}>01.&nbsp;</span>About Me
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                }}>
                <Grid container spacing={2} sx={{}}>
                    <Grid item sm={12} md={6}>
                        <Box
                            sx={{marginLeft: 6}}>
                            <Typography variant={"body1"}
                                        style={{whiteSpace: 'pre-line'}}
                            >
                                {bio}
                            </Typography>
                            <Box sx={{
                                marginTop: "1rem",
                                display:"flex",
                                justifyContent: "start",

                            }}>
                                <SocialMediaIcons/>

                            </Box>

                        </Box>


                    </Grid>
                    <Grid item sm={12} md={6}
                          sx={{display: "flex", justifyContent: "center"}}>
                        <Box
                            sx={{
                                maxWidth: "70%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Box
                                sx={{
                                    backgroundColor: "#EF7B45",

                                    boxShadow: "2rem 2rem #4C956C"
                                }}>
                                <Box component={"img"} src={profilePic}
                                     sx={{}}/>
                            </Box>

                        </Box>
                    </Grid>

                </Grid>

            </Box>

        </Box>
    )
}