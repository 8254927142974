import {Box, Grid, Typography} from "@mui/material";
import jzPic from "../asset/images/jz.png";
import {Typewriter} from 'react-simple-typewriter';

export default function Intro() {
    const smallerFontStyle = {
        '&:hover': {
            cursor: "pointer",
            textDecoration: "underline"
        },
        fontWeight: 400,
        fontFamily: "Space Mono",
        fontSize: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "2rem",
        }
    }
    const biggerFontStyle = {
        fontSize: {
            xs: "2.5rem",
            sm: "4rem",
        },
        marginRight: {
            xs: "0.75rem",
            sm: "1.5rem",
        },
        fontWeight: 800
    }
    return (
        <Grid
            id={"intro"}
            container spacing={2}
            sx={{padding: "3rem 3rem 0 3rem"}}>
            <Grid xs={12} sm={12} md={6}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                    <Box
                        sx={{
                            marginTop: {
                                xs: "0rem",
                                sm: "5rem"
                            },
                            width: {
                                xs: "100%",
                                sm: "75%"
                            },
                            textAlign: "right"
                        }}>
                        <Box>
                            <Typography
                                sx={{...biggerFontStyle, marginBottom: "1rem"}}
                                variant={"h3"}>
                                Hi! My name is
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: 'Ultra',
                                fontSize: {
                                    xs: "2rem",
                                    sm: "4rem"
                                },
                                color: '#EF7B45',
                            }}>

                            <Typewriter
                                words={[" Jialiang Zhou", " JZ"]}
                                loop={1}
                                cursor
                                cursorStyle='|'
                                typeSpeed={70}
                                deleteSpeed={50}
                                delaySpeed={1000}
                            />

                        </Typography>
                        <Box
                            sx={{}}>
                            <Typography sx={biggerFontStyle}>
                                周嘉良
                            </Typography>
                        </Box>
                        <Box sx={{
                            marginTop: {
                                xs: "1rem",
                                sm: "2rem"
                            }
                        }}>
                            <Typography

                                sx={{
                                    ...smallerFontStyle,
                                }}>
                                Product Designer
                            </Typography>
                            <Typography sx={{
                                ...smallerFontStyle,
                            }}>
                                Fullstack Developer
                            </Typography>
                            <Typography sx={{
                                ...smallerFontStyle,
                            }}>
                                Startup Founder
                            </Typography>
                            <Typography sx={{
                                ...smallerFontStyle,
                            }}>
                                Maker
                            </Typography>
                            <Typography sx={{
                                ...smallerFontStyle,
                            }}>
                                Musician
                            </Typography>
                        </Box>
                    </Box>
                </Box>

            </Grid>
            <Grid xs={12} sm={12} md={6}
                  sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center"
                  }}>
                <Box>
                    <Box
                        sx={{
                            marginTop: "3rem"
                        }}
                        component="img"
                        alt="A picture of me(JZ)"
                        src={jzPic}
                    />
                </Box>
            </Grid>
        </Grid>


    )
}