import {createTheme} from "@mui/material";
import "../fonts.css"
export const projectTheme = createTheme({
    typography:{
        //Project Heading

        h1:{
            color:"#212427",

            fontFamily: "Ultra",
            fontSize: "4rem",
        },
        subtitle1:{
            color:"rgba(33,36,39,0.71)",
            fontFamily: "Roboto",
            fontSize:"2rem",
        },

        h2: {
            color:"#212427",
            fontFamily: "Space Mono",
            fontSize:"2rem",
            fontWeight: "600",
        },
        h3: {
            color:"#212427",
            fontFamily: "Space Mono",
            fontSize:"1rem",
            fontWeight: "600",
        },
        body1:{
            color:"#212427",
            fontFamily: "Roboto",
        }
    },
})