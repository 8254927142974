import {Box, Typography} from "@mui/material";
import SocialMediaIcons from "./SocialMediaIcons";

export default function Footer() {
    return (
        <Box
            sx={{
                marginTop: "4rem",
                width: "100%",
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems:"center",
                flexDirection: "column",
                gap: "1rem",
                paddingBottom: "5rem"
            }}>
            <Box
                sx={{
                    // backgroundColor:"red"
                }}>
                <Typography
                    sx={{
                        fontWeight:"500"
                    }}>
                    Designed and Made by <span
                    style={{
                        fontFamily: 'Ultra',
                        color: '#EF7B45',
                    }}>
                    JZ
                </span>
                </Typography>
            </Box>
            <SocialMediaIcons/>



            {/*<Box*/}
            {/*    sx={{*/}
            {/*        // textAlign: "center"*/}
            {/*    }}>*/}
            {/*    <Typography*/}
            {/*        sx={{}}>*/}
            {/*        Designed and Made by <span*/}
            {/*        style={{*/}
            {/*            fontFamily: 'Ultra',*/}
            {/*            color: '#EF7B45',*/}
            {/*        }}>*/}
            {/*        JZ*/}
            {/*    </span>*/}
            {/*    </Typography>*/}
            {/*    <Box*/}
            {/*        sx={{*/}
            {/*        }}>*/}
            {/*        <SocialMediaIcons/>*/}
            {/*    </Box>*/}

            {/*</Box>*/}


        </Box>
    )
}