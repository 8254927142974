import {Box} from "@mui/material";
import {Project} from "../../../projects/Project";
import React from "react";

interface FlatProjectDisplayCard {
    project: Project,
    setPage: React.Dispatch<React.SetStateAction<string>>
}

export default function FlatProjectDisplayCard(props: FlatProjectDisplayCard) {
    return (
        <Box
            onClick={() => {

                window.scrollTo(0, 0);
                props.setPage(props.project.id.toString());
            }}
            sx={{}}>
            <Box component="img" src={props.project.thumbnail}
                 sx={{
                     cursor: 'pointer',
                     "&:hover": {
                         filter: "contrast(175%)",
                         boxShadow: '0 0 2px 3px rgb(76, 149, 108)',
                         transition: "box-shadow 0.2s ease-in-out"
                     }
                 }}
                 alt={props.project.shortName}/>

        </Box>
    )
}