import {Project} from "./Project";
import winnoThumbnail from "./asset/winno-thumbnail.jpeg";
import poshmarkThumbnail from "./asset/poshmark-thumbnail.png";
import siftThumbnail from "./asset/sift-thumbnail.png";
import lentiThumbnail from "./asset/lenti-thumbnail.png";
const winno: Project = {
    id: 0,
    shortName: "Product Design: Winno",
    longName: "Product Design: Winno",
    description: "ProjectPageWinno is a Y-Combinator backed startup that lets user text messages to monetize exclusive content and 1:1 interactions. Without looking at the actual product made by ProjectPageWinno, my team decided to design a product solving the same problem that ProjectPageWinno solves.\n" +
        "\n" +
        "More specifically, we tried to design a product that addresses 2 key needs.\n" +
        "1. people want to connect with experts in different fields to ask their pressing questions.\n" +
        "2. experts and creators want to own their audience and get paid directly by their top fans",
    thumbnail:winnoThumbnail,
    keywords: ["Figma","Product Design","User Testing"],
    link:"https://lazyelephant420.github.io/iterative/"

}

const poshmark: Project = {
    id: 1,
    shortName: "Usability Redesign: Poshmark",
    longName: "Website Usability Redesign: Poshmark",
    description: "As someone who frequently browses online secondhand shops, " +
        "I noticed that the shopping experience on Poshmark has often been " +
        "sub-optimal. However, it has one of the largest databases of secondhand " +
        "apparel products, so I decided to redesign the landing page of Poshmark " +
        "to make it easier for users to shop." +
        "\n\nAs I was redesigning the webpage, " +
        "I tried to keep in mind the goals that the original webpage was trying " +
        "to achieve so that I can make the webpage more usable without " +
        "sacrificing the original goals of the page. At the same time, I tried to" +
        "make sure that the website is responsive to different screen sizes",
    thumbnail:poshmarkThumbnail,
    keywords: ["Bootstrap", "HTML", "CSS"],
    link:"https://olddragon000.github.io/responsive-redesign/"
}

const sift: Project = {
    id: 2,
    shortName: "Building a Startup: Sift",
    longName: "Building a Tech Startup: Sift",
    description: "I put my product design and frontend development skills to test " +
        "with my startup Sift. \n\nEven though 70% of Americans are willing to purchase " +
        "secondhand clothing items, only 30% do. Shopping secondhand, especially online, is not " +
        "as popular as it should be because people already have a habit of shopping on fast " +
        "fashion sites and secondhand sites are harder to navigate. \n\nSift is a Chrome extension" +
        " that allows people to find secondhand alternatives to the fast fashion " +
        "products that they are interested in within a click.\n\n" +
        "Apart from leading the product development and engineering process and ensuring that the team is " +
        "communicating well, I was also in charge " +
        "of the entire frontend development process. content scripts, I built an extension " +
        "that can extract product information on a page, " +
        "send product information to the backend, receive recommendations and display products " +
        "on the webpage.",
    thumbnail:siftThumbnail,
    keywords: ["Product Management", "Frontend Development"],
    link:"https://www.letsift.com"
}

const lenti: Project = {
    id:3,
    shortName: "Humanity Centered Design: Lenti",
    longName:"Humanity Centered Design: Lenti",
    description:"",
    thumbnail: lentiThumbnail,
    keywords:["Raspberry Pi", "Humanity Centered Design"],
    link:"https://its-jz.github.io/lenti-webiste/"
}


export const projects: {[id: number] : Project} ={
    0:winno,
    1:poshmark,
    2: sift,
    3: lenti,
}