import {Box, Fade, Typography} from "@mui/material";
import React from "react";
import "../fonts.css";
import {Link} from "react-scroll";

interface NavBarProps {
    isScrollingUp: boolean
}


export default function NavBar(props: NavBarProps) {
    const navBarOptionStyles = {
        fontSize: {
            xs: "0.8rem",
            sm: "1.1rem",
            md: "1.5rem"
        },
        fontWeight: 700,
        letterSpacing: "0.001rem",
        fontFamily: "Space Mono",
        marginRight: "0.5rem",
        cursor: "pointer",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",

    }
    return (
        <Fade in={props.isScrollingUp}>
            <Box
                sx={{
                    backdropFilter: "blur(3px)",
                    position: "sticky",
                    top: 0,
                    height: "4rem",
                }}>

                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: 2,
                        marginLeft: {
                            xs: 2,
                            md: 5
                        },
                        marginRight: {
                            xs: 2,
                            md: 5
                        },
                        position: "relative"
                    }}>
                    <Box
                        sx={{
                            display: "flex",

                        }}>
                        <Typography variant="h5" component="div"
                                    sx={{
                                        fontFamily: "Ultra",
                                        fontSize: {
                                            xs: "2rem",
                                            sm: "3rem",
                                            md: "3rem"
                                        },
                                        color: "#EF7B45",
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        flexGrow: 20,
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                            <Link activeClass="active" to={"intro"} spy={true}
                                  smooth={true} duration={500}>
                                JZ
                            </Link>

                        </Typography>


                        {["about","projects","music"].map((option) =>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={navBarOptionStyles}>
                                <Link activeClass="active" to={option} spy={true}
                                      smooth={true} duration={500}>
                                    {option}
                                </Link>
                            </Typography>)}


                    </Box>
                </Box>
            </Box>
        </Fade>
    )
}