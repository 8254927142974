import {Box, Grid, Typography} from "@mui/material";
import FlatProjectDisplayCard
    from "../components/projectsDisplay/flatProjectDisplay/FlatProjectDisplayCard";
import {selectedProjectIds} from "./SelectedProjectIds";
import {projects} from "./Projects";
import React from "react";

interface ProjectsFlatDisplayProps {
    setPage: React.Dispatch<React.SetStateAction<string>>
}

export default function ProjectsFlatDisplay(props: ProjectsFlatDisplayProps) {
    return (
        <Box
            sx={{
                width: "100%"
            }}>
            <Box
                sx={{}}>
                <Typography variant={"h3"}>
                    <span style={{color: "#4C956C"}}>02.&nbsp;</span>Projects
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {selectedProjectIds.map(id =>
                    <Grid item sm={12} md={6}
                          sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                          }}>
                        <FlatProjectDisplayCard project={projects[id]} setPage={props.setPage}/>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
